






































































































































































































































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import SelectTrainingLocation from '@/components/molecules/select-search/location.vue'
import DateField from '@/components/base/DateField.vue'
import TimeField from '@/components/base/TimeField.vue'
import BaseMaterialCard from '@/components/base/MaterialCard.vue'
import TrainingMeals from '@/components/molecules/logisticSession/trainingMeals.vue'
import { mapActions, mapState } from 'vuex'
import NeedTrainingsTableList from '@/components/molecules/logisticSession/needTrainingsTableList.vue'
import CompanyListWithCostsTableList from '@/components/molecules/logisticSession/companyListWithCostsTableList.vue'
import MDialogDocument from '@/components/molecules/downloadDocumentDialog/m-dialog-document.vue'
import SelectTrainingSessionStatus from '@/components/molecules/select/trainingSessionStatus.vue'
import { TrainingType, TimeSlot } from '@/api/interfaces/trainingtype'
import { getHours, getMinutes } from 'date-fns'
import { useDate } from '@/composables/date'
import { userHasRole } from '@/composables/UserGrant'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import ConfirmModal from '@/components/molecules/dialog/confirmModal.vue'
import { TrainingSessionStatus } from '@/api/enums/trainingSessionStatus'
import { useTrainingSession } from '@/composables/trainingSession'
import SelectSearchTrainingType from '@/components/molecules/select-search/trainingType.vue'

export default defineComponent({
  setup () {
    const canExportTrainee = () => userHasRole('ROLE_EXPORT_TRAINEE')

    return {
      canExportTrainee,
    }
  },
  name: 'TrainingSessionForm',
  components: {
    SelectSearchTrainingType,
    ConfirmModal,
    CompanyListWithCostsTableList,
    NeedTrainingsTableList,
    TopBar,
    SelectTrainingLocation,
    DateField,
    TimeField,
    BaseMaterialCard,
    TrainingMeals,
    MDialogDocument,
    SelectTrainingSessionStatus,
    TeachingAids: () => import('@/components/molecules/input/teachingAids.vue'),
    DialogChangeTimeSlot: () => import('@/components/molecules/dialog/changeTimeSlot.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: {},
    },
    logisticSession: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      dialogDocument: false,
      dialogDissociate: false,
      dialogChangeTrainingSessionHours: false,
      isConfirmDissociateLoading: false,
      trainingSession: this.value,
    }
  },
  created () {
    this.init()
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.trainingSession = this.value
          this.init()
        }
      },
    },
    logisticSession: {
      handler () {
        this.initStartAt()
      },
    },
    'trainingSession.trainingType': {
      handler (
        newVal: TrainingType,
        oldVal: TrainingType,
      ) {
        if (newVal && oldVal && newVal['@id'] !== oldVal['@id']) {
          this.changeTrainingTypeInformations(newVal)
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('logisticSessionForm', {
      dissociateTrainingSession: 'dissociate',
    }),
    init () {
      this.initStartAt()
      if (!this.trainingSession['@id']) {
        this.dialogChangeTrainingSessionHours = true
      }
      if (!this.trainingSession.status) {
        this.trainingSession.status = TrainingSessionStatus.STATUS_OPTIONAL
      }
    },
    initStartAt () {
      if (this.logisticSession.startAt && !this.trainingSession['@id']) {
        this.trainingSession.startAt = this.logisticSession.startAt
      }
    },
    exportTrainees (id: string): string {
      return '/api/need_trainings/export?trainingSession.id=' + id
    },
    changeTrainingSessionHours (timeSlot: TimeSlot) {
      const start = new Date('1970-01-01 ' + timeSlot.startAt)
      const end = new Date('1970-01-01 ' + timeSlot.endAt)
      this.trainingSession.startAt = useDate().createFormatISO(this.trainingSession.startAt as string, getHours(start), getMinutes(start))
      this.trainingSession.endAt = useDate().createFormatISO(this.trainingSession.endAt as string, getHours(end), getMinutes(end))
    },
    onChangeTrainingLocation () {
      if (
        this.trainingSession.trainingLocation &&
        (
          !this.trainingSession.goalNumberTrainees ||
          this.trainingSession.goalNumberTrainees > this.trainingSession.trainingLocation.maximumCapacity
        )
      ) {
        this.trainingSession.goalNumberTrainees = this.trainingSession.trainingLocation.maximumCapacity
      }
    },
    async dissociate () {
      try {
        this.isConfirmDissociateLoading = true
        await this.dissociateTrainingSession(this.trainingSession.id)
      } finally {
        this.isConfirmDissociateLoading = false
        this.dialogDissociate = false
      }
    },
    changeTrainingTypeInformations (trainingType: TrainingType) {
      this.trainingSession.teachingAids = trainingType.teachingAids
      this.trainingSession.trainingPlanningSharepointLink = trainingType.trainingPlanningSharepointLink
    },
  },
  computed: {
    numberTraineesInSession (): number {
      return useTrainingSession().getNumberTraineesInSession(this.trainingSession as TrainingSession)
    },
    getMessageDissociate (): string {
      return this.$t('training_session.form.dissociate', {
        training_type: this.trainingSession.trainingType.name,
        start_at: useDate().getDateFormat(this.trainingSession.startAt, 'hh:mm'),
        end_at: useDate().getDateFormat(this.trainingSession.endAt, 'hh:mm'),
      }) as string
    },
  },
})
