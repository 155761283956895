import { format, formatISO } from 'date-fns'

export function useDate () {
  function createFormatISO (date: string | Date, hours: number, minutes: number) {
    return formatISO(new Date(date).setHours(hours, minutes, 0))
  }

  function getDateFormat (date: string, dateFormat: string) {
    return format(new Date(date), dateFormat)
  }

  return {
    createFormatISO,
    getDateFormat,
  }
}
