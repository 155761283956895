






































import { defineComponent, inject } from '@vue/composition-api'
import { Company } from '@/api/interfaces/company'
import { Trainee } from '@/api/interfaces/trainee'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
  setup () {
    const apiUrl = inject('getApiUrl')

    return {
      apiUrl,
    }
  },
  name: 'v-document-item',
  props: {
    session: {
      type: Object as () => TrainingSession | LogisticSession,
      required: true,
    },
    header: {
      type: Array,
      required: false,
      default: () => [],
    },
    data: {
      type: Array,
      required: true,
    },
    documentName: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      headerCommon: [
        {
          text: this.$t('form.name'),
          value: 'name',
        },
        {
          text: this.$t('form.actions'),
          value: 'actions',
        },
      ],
      baseURL: '/',
      urlPrint: {
        baseTrainingSession: 'print/training_sessions',
        baseLogisticSession: 'print/logistic_session',
      },
    }
  },
  computed: {
    ...mapState('logisticSessionForm', {
      logisticSession: 'logisticSession',
    }),
    ...mapState('DocumentGenerate', {
      mentionClimat: 'mentionClimat',
    }),
  },
  methods: {
    ...mapActions('DocumentGenerate', {
      addMentionClimat: 'addMentionClimat',
    }),
    addMentionClimatTrainee (trainee: Trainee) {
      this.addMentionClimat(trainee)
    },
    getLinkDocument (
      type: string,
      item: unknown,
      session: TrainingSession | LogisticSession,
    ): string {
      const urlStart = `${this.apiUrl}${this.urlPrint.baseTrainingSession}/${session.id}`
      const ext = '.pdf'
      switch (type) {
        case 'attestation': {
          const trainee = item as Trainee
          const index = (this.mentionClimat as Array<Trainee>).findIndex((t) => t.id === trainee.id)
          const mentionClimatParam = index !== -1 ? '?mentionClimat=1' : ''
          return `${urlStart}/attestation/${
            trainee.id
          }/attestation_${trainee.firstName}_${
            trainee.lastName
          }_${new Date().getTime()}${ext}${mentionClimatParam}`
        }
        case 'convocation_par_societes': {
          const company = item as Company
          return `${urlStart}/convocation/${company.id}/convocation_${
            company.name
          }_${new Date().getTime()}${ext}`
        }
        case 'convocation_par_marques_et_societes': {
          const object = item as any
          if (object.type === 'company') {
            return `${urlStart}/convocation/${object.id}/convocation_${
              object.value
            }_${new Date().getTime()}${ext}`
          }

          return `${urlStart}/convocation_hybrid_academy/convocation_${object.value}_${new Date().getTime()}${ext}`
        }
        case 'convention': {
          const company = item as Company
          return `${urlStart}/convention/${company.id}/convention_${
            company.name
          }_${new Date().getTime()}${ext}`
        }
        case 'emargement': {
          return `${urlStart}/emargement/emargement_${new Date().getTime()}${ext}`
        }
        case 'emargement_par_societe': {
          const company = item as Company
          return `${urlStart}/emargement/${company.id}/emargement_${
            company.name
          }_${new Date().getTime()}${ext}`
        }
        case 'evaluation': {
          const company = item as Company
          return `${urlStart}/evaluation/${company.id}/evaluation_${
            company.name
          }_${new Date().getTime()}${ext}`
        }
        case 'flyleaf': {
          const urlStart = `${this.apiUrl}${this.urlPrint.baseLogisticSession}/${session.id}`
          return `${urlStart}/flyleaf_${new Date().getTime()}${ext}`
        }
        case 'preparation-materiel': {
          const urlStart = `${this.apiUrl}${this.urlPrint.baseLogisticSession}/${session.id}`
          return `${urlStart}/preparation_materiel_${new Date().getTime()}${ext}`
        }
        default:
          return ''
      }
    },
  },
})
